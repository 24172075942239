<template>
  <el-dialog
    title="添加"
    :visible.sync="show"
    width="700px"
    center
  >
  <el-form v-if="show" :model="config" ref="config" label-width="80px" :rules="rules" :hide-required-asterisk="true">
    <el-row>
        <el-col :span="24">
            <el-form-item label="类型:" prop="type">
                <el-select @change="changeClear($event)" v-model="config.type">
                    <el-option v-for="i in typeSelect"
                    :label="i" :key="i" :value="i" >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="24" v-if="config.type==='object'">
            <el-form-item label="值:">
            <el-row :gutter="10">
                <el-col :span="4">键名</el-col>
                <el-col :span="16">键值</el-col>
            </el-row>
            <el-row :gutter="10" v-for="(values,keys) in Objvalues" :key="keys">
                <el-col :span="4"><el-input type="text"   v-model="values.key"></el-input></el-col>
                <el-col :span="16"><el-input type="text" v-model="values.value"></el-input></el-col> 
                <el-col :span="4"><el-button type="danger" size="mini" @click="deleteValueAll(keys)">X</el-button> </el-col>
            </el-row>
            <el-button size="mini" type="success" @click="addNodeAll()">追加</el-button>
            </el-form-item>
        </el-col>
        <el-col :span="24" v-else>
            <el-form-item label="值:" prop="value">
            <el-input v-if="config.type==='text'" v-model="config.value" type="textarea" rows="3"></el-input>
            <el-switch active-text="是" inactive-text="否" v-else-if="config.type==='bool'"  v-model="config.value"></el-switch>
            <el-input v-else-if="config.type==='int'" v-model.number="config.value"></el-input>
            <el-input v-else v-model="config.value" ></el-input>
            </el-form-item>
        </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
            <el-form-item label="名称:" prop="name">
                <el-input v-model="config.name" ></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="标题:" prop="title">
                <el-input v-model="config.title" ></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="内容:">
                <el-input v-model="config.content" ></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="是否必填:">
                <el-switch v-model="config.is_required"></el-switch>
            </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="排序:" prop="sort_order">
                <el-input v-model.number="config.sort_order" ></el-input>
            </el-form-item>
            </el-col>
        </el-row>
    </el-form>
  <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";
export default {
    data(){
        var checkName = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('请输入名称'));
            }else{
            let reg = /[^\w\\]/g
            if (reg.test(value)) {
                callback(new Error('目前只支持英文数字，不包括特殊字符'));
            } else {
                callback();
            }
            }
        };
        return{
            show:false,
            config: {
                name: '',
                title: '',
                tip: '',
                type: '',
                value: '',
                content: '',
                is_required: true,
                sort_order: '',
            },
            typeSelect: ['text', 'string', 'object', 'int', 'bool'],
            rules:{
                name: [{ validator: checkName, trigger: 'blur' }],
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                value: [{ required: true, message: '请输入值', trigger: 'blur' }],
                sort_order: [{ required: true, message: '请输入排序', trigger: 'blur' }]
            },
            Objvalues:[
                {key:"",value:""}
            ],
        }
    },
    methods:{
        addFormData(){
            this.show = true
            this.config = {
                name: '',
                title: '',
                tip: '',
                type: '',
                value: '',
                content: '',
                is_required: true,
                sort_order: '',
            }
        },
        submit(){
            if(this.config.type==='object' && !this.Objvalues.length){
                this.$message({
                    type:"warning",
                    message:"请输入键名以及键值"
                })
                return false
            }
            this.$refs.config.validate((valid) => {
                valid ? this.addDatas() : "";
            });
        },
        addDatas(){
            let datas = JSON.parse(JSON.stringify(this.config))
            if(datas.type==='object'){
                let str = {}
                this.Objvalues.forEach(v=>{
                    let obj ={}
                    let key = v.key
                    obj[key] = v.value
                    str = Object.assign(str,obj)
                })
                datas.value = JSON.stringify(str)
            }
            if(datas.type==='int' || datas.type==='bool'){
                datas.value = datas.value.toString()
            }
            API.createConfig(datas).then(res=>{
                if(!res.code){
                    this.$message.success('添加成功')
                    this.show = false
                    this.$emit('getLists')
                }else{
                    this.show = false
                    this.$message.error(res.msg)
                }
            })
        },
        changeClear(e,key){
            console.log(e,key)
            if (e=='bool'){
                this.$set(this.config,'value',true)
            }else if(e=='int'){
                this.$set(this.config,'value',0)
            }else{
                this.$set(this.config,'value',"")
            }
        },
        deleteValueAll(index){
            this.Objvalues.splice(index,1)
        },
        addNodeAll(){
            this.Objvalues.push({key:"",value:""})
        },
    }
}
</script>

<style>

</style>