<template>
  <div>
    <div class="top">
      <p class="topTitle">系统配置</p>
      <div class="topText">
        可以在此增改系统的变量和分组，也可以自定义分组和变量
      </div>
    </div>
    <div class="btnboxs">
      <div>
        <p>变量标题</p>
        <p>变量值</p>
      </div>
      <div>
        <el-button @click="refesh()" size="small">刷新</el-button>
        <el-button
          type="primary"
          size="small"
          v-has="'/api/general/config/create'"
          @click="addForm"
          >添加</el-button
        >
      </div>
    </div>
    <div
      class="list"
      v-if="dataList.length > 0"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
    >
      <el-form
        :model="object"
        ref="form1"
        label-width="200px"
        label-position="left"
        class="demo-dynamic"
      >
        <el-form-item
          v-for="item in dataList"
          :key="item.id"
          :label="item.title"
          :rules="[
            { required: item.is_required, message: '请输入', trigger: 'blur' },
          ]"
        >
          <div class="objboxs" v-if="item.type === 'object'">
            <el-row :gutter="10">
              <el-col :span="10">键名</el-col>
              <el-col :span="14">键值</el-col>
            </el-row>
            <el-row
              :gutter="10"
              v-for="(values, keys) in item.values"
              :key="keys"
            >
              <el-col :span="10"
                ><el-input type="text" v-model="values.key"></el-input
              ></el-col>
              <el-col :span="10"
                ><el-input type="text" v-model="values.value"></el-input
              ></el-col>
              <el-col :span="4"
                ><el-button
                  type="danger"
                  size="mini"
                  @click="deleteValue(item, keys)"
                  >X</el-button
                >
              </el-col>
            </el-row>
            <el-button size="mini" type="success" @click="addNode(item)"
              >追加</el-button
            >
          </div>
          <el-input
            v-else-if="item.type === 'text'"
            type="textarea"
            rows="3"
            v-model="item.value"
          ></el-input>
          <el-input
            v-else-if="item.type === 'int'"
            v-model.number="item.value"
          ></el-input>
          <el-switch
            v-else-if="item.type === 'bool'"
            active-value="true"
            inactive-value="false"
            active-text="是"
            inactive-text="否"
            v-model="item.value"
          ></el-switch>
          <el-input v-else v-model="item.value"></el-input>
          <el-button
            type="danger"
            size="mini"
            @click="deleteData(item)"
            v-has="'/api/general/config/destroy'"
            >X</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="nodata" v-else>暂无数据</div>

    <el-button
      style="margin: 0 auto; display: flex"
      type="primary"
      @click="updateData"
      v-has="'/api/general/config/update'"
    >
      更新
    </el-button>
    <addFrom ref="addFrom" @getLists="getData"></addFrom>
  </div>
</template>

<script>
import API from "@/api";
import Api from "@/api";
import addFrom from "./components/addSysem.vue";
export default {
  name: "system",
  components: { addFrom },
  data() {
    return {
      activeName: "first",
      object: {},
      dataList: [],
      loading: false,
      isRefesh: true,
    };
  },
  created() {
    let listRule = "/api/general/config/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    console.log(obj[listRule]);
    if (obj[listRule]) {
      this.isRefesh = true;
      this.getData();
    } else {
      this.isRefesh = false;
    }
  },
  methods: {
    refesh() {
      this.isRefesh ? this.getData() : "";
    },
    getData() {
      this.loading = true;
      API.getConfigList().then((res) => {
        if (!res.code) {
          let data = res.data.data;
          data.forEach((item) => {
            if (item.type === "object") {
              console.log(item.value);
              item.value = JSON.parse(item.value);
              item.values = [];
              for (const key in item.value) {
                item.values.push({ key: key, value: item.value[key] });
              }
            }
          });
          this.dataList = data;
          this.loading = false;
        } else {
          this.dataList = [];
          this.loading = false;
        }
        // console.log(res.data.data.filter(v=>v.type==='text'));
      });
    },
    changeInput(e, k, key) {
      console.log(e, key);
      console.log(e.target.value);
      let newKey = e.target.value;
      this.config[key].value[newKey] = this.config[key].value[k];
      delete this.config[key].value[k];
      console.log("this.config[key].value", this.config[key].value);
    },
    deleteObject(k, key) {
      console.log(k, key);
      console.log("this.config[key].value[k]", this.config[key].value[k]);
      delete this.config[key].value[k];
      this.$forceUpdate();
    },
    addObject(key) {
      let newKey = "newKey" + Date.now();
      let newValue = "newValue" + Date.now();
      this.config[key].value = {
        ...this.config[key].value,
        [newKey]: newValue,
      };
      this.$forceUpdate();
    },
    deleteData(data) {
      Api.deleteConfig({ id: data.id }).then((res) => {
        if (!res.code) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData() {
      this.dataList.forEach((item) => {
        if (item.type === "object") {
          let str = {};
          item.values.forEach((v) => {
            let obj = {};
            let key = v.key;
            obj[key] = v.value;
            str = Object.assign(str, obj);
          });
          item.value = JSON.stringify(str);
        }
      });
      Api.updateConfig({ configs: this.dataList }).then((res) => {
        if (!res.code) {
          this.$message.success("更新成功");
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSubmit() {},
    handleClick(tab, event) {
      console.log(tab, event);
    },
    deleteValue(data, index) {
      data.values.splice(index, 1);
    },
    addNode(data) {
      console.log(data);
      data.values.push({ key: "", value: "" });
    },
    addForm() {
      this.$refs.addFrom.addFormData();
    },
  },
};
</script>

<style lang="scss" scoped>
.list ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-row {
  margin-bottom: 20px;
}
.addbox ::v-deep .el-form-item__label {
  font-weight: bold;
  font-size: 15px;
}
.top {
  padding: 10px;
  width: 100%;
  height: 80px;
  background: #e9edf0;
  margin-bottom: 20px;

  .topText {
    margin-top: 10px;
    font-size: 14px;
    color: #909399;
  }
}
.demo-dynamic {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
}
.content {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  .content-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .content-item-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .content-item-row-object {
    // .objectClass {
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    h4 {
      margin-bottom: 10px;
    }

    .objectItem {
      display: flex;
      text-align: center;
      align-items: center;
    }
    // }
  }

  span {
    margin-right: 10px;
  }

  h4 {
    margin-right: 10px;
  }

  .el-input {
    margin-right: 10px;
  }

  .el-button {
    margin-right: 10px;
  }

  .el-switch {
    margin-right: 10px;
  }

  .lr {
    margin-right: 300px;
    width: 800px;
  }
}
.btnboxs {
  > div {
    display: flex;
    font-weight: bold;
    p {
      width: 200px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: solid 1px #e9edf0;
  margin-bottom: 10px;
}
::v-deep .el-textarea,
.el-input {
  width: 60%;
  margin-right: 10px;
}
.objboxs {
  width: 60%;
  margin-right: 10px;
  ::v-deep .el-col > .el-input {
    width: 100%;
  }
}
.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px 0;
  color: #606266;
}
</style>
